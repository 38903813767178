var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('v-row',{staticClass:"mb-5"},[_c('div',{staticClass:"text-left"},[_c('h1',{staticClass:"ocupation__title"},[_vm._v("Malla de turno")])]),(_vm.showData)?_c('v-row',_vm._l((this.hoursPerWeeks),function(weekData,index){return (weekData.weekNumber == _vm.weekToShow)?_c('v-col',{key:index,staticClass:"box-activity",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"status-box status-box-total"},'div',attrs,false),on),[_c('small',[_vm._v("Intensidad horaria semanal")]),_c('p',{staticClass:"status-box-p"},[_vm._v(" "+_vm._s(Math.floor(weekData.totalHours * 10) / 10)+" ")])])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"status-box status-box-total"},'div',attrs,false),on),[_c('small',[_vm._v("Intensidad horaria semanal ejecutada")]),_c('p',{staticClass:"status-box-p"},[_vm._v(" "+_vm._s(Math.floor(weekData.hoursWorked * 10) / 10)+" ")])])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"status-box status-box-total"},'div',attrs,false),on),[_c('small',[_vm._v("horas deuda")]),(weekData.debtHour == '')?_c('p',{staticClass:"status-box-p"},[_vm._v("0")]):_c('p',{staticClass:"status-box-p"},[_vm._v(" "+_vm._s(Math.floor(weekData.debtHour * 10) / 10)+" ")])])]}}],null,true)})],1):_vm._e()}),1):_vm._e()],1),_c('v-sheet',{staticClass:"d-flex",attrs:{"tile":"","height":"54"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.types,"dense":"","outlined":"","hide-details":"","label":"Tipo"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.weekdays,"dense":"","outlined":"","hide-details":"","label":"Días de la semana"},model:{value:(_vm.weekday),callback:function ($$v) {_vm.weekday=$$v},expression:"weekday"}}),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-flex',[_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"click:more":_vm.viewDay,"change":_vm.getEvents},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#3957B9","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.$translate("general.loading"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }