<template>
  <div class="pl-8 pr-8">
    <v-row class="mb-5">
      <div class="text-left">
        <h1 class="ocupation__title">Malla de turno</h1>
      </div>
      <v-row v-if="showData">
        <v-col
          v-for="(weekData, index) in this.hoursPerWeeks"
          :key="index"
          cols="12"
          class="box-activity"
          v-if="weekData.weekNumber == weekToShow"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-total" v-bind="attrs" v-on="on">
                <small>Intensidad horaria semanal</small>
                <p class="status-box-p">
                  {{ Math.floor(weekData.totalHours * 10) / 10 }}
                </p>
              </div>
            </template>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-total" v-bind="attrs" v-on="on">
                <small>Intensidad horaria semanal ejecutada</small>
                <p class="status-box-p">
                  {{ Math.floor(weekData.hoursWorked * 10) / 10 }}
                </p>
              </div>
            </template>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-total" v-bind="attrs" v-on="on">
                <small>horas deuda</small>
                <p class="status-box-p" v-if="weekData.debtHour == ''">0</p>
                <p class="status-box-p" v-else>
                  {{ Math.floor(weekData.debtHour * 10) / 10 }}
                </p>
              </div>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-row>

    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipo"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Días de la semana"
        class="ma-2"
      ></v-select>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <v-flex>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @click:more="viewDay"
          @change="getEvents"
        ></v-calendar>
      </v-sheet>
    </v-flex>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<!-- Link de flujo:// -->
<script>
import Api from "@/utils/api";
export default {
  data: () => ({
    type: "month",
    types: [
      { text: "Mes", value: "month" },
      { text: "Semana", value: "week" },
      { text: "Dias", value: "day" },
    ],
    mode: "column",
    modes: ["column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Lunes - Domingo", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Lunes - Viernes", value: [1, 2, 3, 4, 5] },
      { text: "Sábado  - Domingo", value: [6, 0] },
    ],
    value: "",
    events: [],
    hoursPerWeeks: [],
    colors: [
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    colorBlue: ["blue"],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    token: "",
    currentUser: "",
    loadingAll: false,
    showData: false,
    weekToShow: null,
  }),

  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getCurrent() {
      if (this.$store.getters.getUser) {
        this.currentUser = this.$store.getters.getUser;
      }
    },
    getMalla() {
      this.loadingAll = true;
      Api.mallaTurno()
        .getMalla(this.currentUser.id, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            console.log(res.data.data);
            this.getEvents(res.data.data);
            this.getHoursWorkedPerWeek(res.data.data);
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.message.dialog = true;
          // this.message.title = "¡Oh no!";
          // this.message.txt = error.response.data.message;
          // this.message.type = "error";
          // this.message.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getEvents(data) {
      if (this.type == "week") {
        this.showHours(data);
      } else {
        this.showData = false;
      }

      const events = [];
      const segmentedEvents = [];

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const item = data[key];
          const [day, month, year] = item.fecha.split("/").map(Number);

          // Evento principal
          const [startHoursT, startMinutesT] = item.t_turno_inicio
            .split(":")
            .map(Number);
          const [endHoursT, endMinutesT] = item.t_turno_fin
            .split(":")
            .map(Number);

          // Crear objeto Date para el evento principal
          const startT = new Date(
            year,
            month - 1,
            day,
            startHoursT,
            startMinutesT,
            0
          );
          const endT = new Date(
            year,
            month - 1,
            day,
            endHoursT,
            endMinutesT,
            0
          );

          // Agregar el evento principal
          events.push({
            name: item.t_novedad_programacion,
            start: startT,
            end: endT,
            color: this.getColorByStatus(item.novedad_kaktus),
            timed: true,
          });
          segmentedEvents.push({
            name: item.novedad_kaktus,
            start: startT,
            end: endT,
            color: this.getColorByStatus(item.novedad_kaktus),
            timed: true,
          });

          // Agregar eventos secundarios si existen
          if (item.inicio_alimentacion && item.fin_alimentacion) {
            const [startHours, startMinutes] = item.inicio_alimentacion
              .split(":")
              .map(Number);
            const [endHours, endMinutes] = item.fin_alimentacion
              .split(":")
              .map(Number);
            const startSub = new Date(
              year,
              month - 1,
              day,
              startHours,
              startMinutes,
              0
            );
            const endSub = new Date(
              year,
              month - 1,
              day,
              endHours,
              endMinutes,
              0
            );

            segmentedEvents.push({
              name: "Alimentación",
              start: startSub,
              end: endSub,
              color: "#cc99ff",
              timed: true,
            });
            events.push({
              name: "Alimentación",
              start: startSub,
              end: endSub,
              color: "blue",
              timed: true,
            });
          }

          if (item.inicio_break && item.fin_break) {
            const [startHours, startMinutes] = item.inicio_break
              .split(":")
              .map(Number);
            const [endHours, endMinutes] = item.fin_break
              .split(":")
              .map(Number);
            const startSub = new Date(
              year,
              month - 1,
              day,
              startHours,
              startMinutes,
              0
            );
            const endSub = new Date(
              year,
              month - 1,
              day,
              endHours,
              endMinutes,
              0
            );
            segmentedEvents.push({
              name: "Break",
              start: startSub,
              end: endSub,
              color: "purple",
              timed: true,
            });
            events.push({
              name: "Break",
              start: startSub,
              end: endSub,
              color: "green",
              timed: true,
            });
          }

          if (item.inicio_capacitacion && item.fin_capacitacion) {
            const [startHours, startMinutes] = item.inicio_capacitacion
              .split(":")
              .map(Number);
            const [endHours, endMinutes] = item.fin_capacitacion
              .split(":")
              .map(Number);
            const startSub = new Date(
              year,
              month - 1,
              day,
              startHours,
              startMinutes,
              0
            );
            const endSub = new Date(
              year,
              month - 1,
              day,
              endHours,
              endMinutes,
              0
            );
            segmentedEvents.push({
              name: "Capacitación",
              start: startSub,
              end: endSub,
              color: "orange",
              timed: true,
            });
            events.push({
              name: "Capacitación",
              start: startSub,
              end: endSub,
              color: "orange",
              timed: true,
            });
          }
        }
      }

      // Ajustar el evento principal según los eventos secundarios
      for (const event of events) {
        const mainStart = event.start.getTime();
        const mainEnd = event.end.getTime();
        const mainDuration = mainEnd - mainStart;

        // Crear segmentos basados en eventos secundarios
        const segments = [{ start: mainStart, end: mainEnd }];
        for (const subEvent of events) {
          if (subEvent !== event) {
            const subStart = subEvent.start.getTime();
            const subEnd = subEvent.end.getTime();
            if (subStart < mainEnd && subEnd > mainStart) {
              const newSegments = [];
              for (const segment of segments) {
                if (subStart < segment.end && subEnd > segment.start) {
                  if (subStart > segment.start) {
                    newSegments.push({ start: segment.start, end: subStart });
                  }
                  if (subEnd < segment.end) {
                    newSegments.push({ start: subEnd, end: segment.end });
                  }
                } else {
                  newSegments.push(segment);
                }
              }
              segments.splice(0, segments.length, ...newSegments);
            }
          }
        }
        // Agregar segmentos al evento principal
        for (const segment of segments) {
          if (segment.end - segment.start < mainDuration) {
            segmentedEvents.push({
              name: event.name,
              start: new Date(segment.start),
              end: new Date(segment.end),
              color: '#66b2ff',
              timed: true,
            });
          }
        }
      }
      const allEvents = [];

      // Agregar los arreglos segmentedEvents y events al arreglo de todos los eventos
      allEvents.push(...segmentedEvents);
      allEvents.push(...events);

      // Asignar el nuevo arreglo de todos los eventos a this.events
      this.events = segmentedEvents;
    },
    getColorByStatus(status) {
      switch (status) {
        case "Ausencia no justificada":
        case "Ausencia no justificada wfm":
          return "red";
        case "Licencia por calamidad":
        case "Licencia remunerada":
        case "Licencia no remunerada":
        case "Licencia por luto":
        case "Licencia de maternidad":
        case "Licencia de paternidad":
          return "#D4EB3D";
        case "Vacaciones":
        case "Descanso":
          return "orange";
        case "Incapacidad enfermedad general":
          return "purple";
        case "Asiste":
          return "green";
        default:
          return "#677972";
      }
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getHoursWorkedPerWeek(data) {
      const hoursPerWeek = [];

      data.forEach((item) => {
        const dateParts = item.fecha.split("/");
        const [day, month, year] = dateParts.map(Number);

        // Crear una fecha a partir de la fecha proporcionada
        const date = new Date(year, month - 1, day);

        // Obtener el número de semana del año
        const weekNumber = this.getWeekNumber(date);

        // Buscar si ya existe una entrada para esta semana en el array
        const existingWeek = hoursPerWeek.find(
          (week) => week.weekNumber === weekNumber
        );

        // Suponiendo que hay un atributo llamado "intensidad_horaria_semanal_ejecutada"
        if (item.intensidad_horaria_semanal) {
          const hoursWorked = parseFloat(item.intensidad_horaria_semanal);

          if (!existingWeek) {
            // Si no existe, crear una nueva entrada para esta semana
            hoursPerWeek.push({
              weekNumber: weekNumber,
              totalHours: hoursWorked,
              debtHour: item.horas_deuda,
              hoursWorked: hoursWorked,
            });
          } else {
            // Si existe, actualizar las propiedades del objeto con los nuevos valores
            existingWeek.totalHours += hoursWorked;
            existingWeek.hoursWorked += hoursWorked;
            existingWeek.debtHour = item.horas_deuda;
          }
        }
      });

      this.hoursPerWeeks = hoursPerWeek;
    },

    getWeekNumber(date) {
      const oneJan = new Date(date.getFullYear(), 0, 1);
      const millisecsInDay = 86400000; // 1000 * 60 * 60 * 24
      return Math.ceil(
        ((date - oneJan) / millisecsInDay + oneJan.getDay() + 1) / 7
      );
    },
    showHours(data) {
      const dateParts = data.end.date.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Los meses en JavaScript son indexados desde 0
      const day = parseInt(dateParts[2]);

      const date = new Date(year, month, day);

      // Creamos una nueva fecha para evitar problemas de cálculo de zona horaria
      const startOfYear = new Date(year, 0, 1);
      const weekNumber = Math.ceil(
        ((date - startOfYear) / 86400000 + startOfYear.getDay() + 1) / 7
      );
      this.weekToShow = weekNumber;
      this.showData = true;
    },
    viewDay({ date }) {
      this.value = date;
      this.type = "day";
    },
  },
  created() {
    this.getToken();
    this.getCurrent();
  },
  mounted() {
    this.getMalla();
  },
  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.getCurrent();
        this.getMalla();
      },
    },
  },
};
</script>
<style scope>
@import "./../../assets/css/main.less";

.status-box {
  margin: 0;
  width: auto !important;
  float: left;
  margin-right: 20px;
  height: 55px;
  padding: 5px;
}
.box-activity {
  display: flex;
  justify-content: flex-end;
}
.status-box-p {
  margin-top: 0px;
  margin-bottom: 0px !important;
  font-size: 18px;
}

.status-box-total {
  background-color: #466be3;
  border: #466be3 1px solid;
  color: #fff;
}

.status-box-free {
  border: dashed #7b7b7b 1px;
  color: #7b7b7b;
}

.status-box-ocu {
  border: #466be3 1px solid;
  color: #7b7b7b;
}
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;

  &.with-time {
    position: absolute;
    right: 4px;
    margin-right: 0px;
  }
}

.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-outer {
  margin-top: 0px !important;
}

/* .hidden div {
  visibility: hidden;
} */

.screen-size {
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #33333363;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.screen-size-text {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #f2f4ff;
}

.size-s {
  width: 14px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-m {
  width: 14px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-l {
  width: 28px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-l2 {
  width: 56px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-xl {
  width: 28px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl2 {
  width: 56px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl3 {
  width: 28px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-xl4 {
  width: 56px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-wall-s {
  width: 14px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-m {
  width: 14px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-l {
  width: 28px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-l2 {
  width: 56px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-xl {
  width: 28px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl2 {
  width: 56px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl3 {
  width: 28px !important;
  height: 56px !important;
  background-color: #000000;
}

.size-wall-xl4 {
  width: 56px !important;
  height: 56px !important;
  background-color: #000000;
}

.text-label-dark-o {
  color: #2e3e75 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 5px !important;
  font-weight: 400;
}

.text-label-o {
  color: #898989 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 10px !important;
}

.text-label-1-o {
  color: #898989 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 0px !important;
}

.name-o {
  font-weight: bold;
  color: #466be3;
  font-size: 18px;
}

.search-o {
  color: #fff;
  background-color: #ff6a29;
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: 1px solid #ff6a29;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.img-profile-o {
  border: 2px solid;
  border-radius: 104px;
  border-color: #466be3;
}

.status-box {
  margin: 0px;
  width: 75px;
  float: left;
  margin-right: 20px;
  height: 55px;
}

.status-box-p {
  margin-top: 0px;
  margin-bottom: 0px !important;
  font-size: 18px;
}

.status-box-total {
  background-color: #466be3;
  border: #466be3 1px solid;
  color: #fff;
}

.status-box-free {
  border: dashed #7b7b7b 1px;
  color: #7b7b7b;
}

.status-box-ocu {
  border: #466be3 1px solid;
  color: #7b7b7b;
}

.st-map {
  width: 1400px;
  height: 800px;
  border: 1px solid;
  border-color: #466be3;
  position: fixed;
}

#ball {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.img-1 {
  text-align: center !important;
  padding-top: 4px !important;
  margin-right: 4px !important;
  background-color: #e2e2e2 !important;
  border-radius: 6px !important;
  border: 1px solid !important;
  border-color: #a19f9f !important;
  width: 30px !important;
  height: 30px !important;
  float: left !important;
}

.txt-pos {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: 1px solid #466be3;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.txt-pos-dis {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: dashed #7b7b7b 1px !important;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.txt-pos-wall {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: auto !important;
}

.txt-pos:hover {
  background-color: #466be3;
  color: #fff;
}

.txt-pos-dis:hover {
  background-color: #ccc;
  /* color: #fff; */
}

.draggable {
  width: 18px;
  height: 14px;
  float: left;
  margin: 2px;
  text-align: center;
}

#containment-wrapper {
  /* width: 100%; */
  width: 1000px;
  /* height: 1010px; */
  height: 705px;
  border: 2px solid #ccc;
  /* padding: 5px; */
  background-size: 100%;
  background-position: bottom;
  margin-bottom: 8px;
}

.new-pos {
  /* margin-top: 15px; */
  width: 100%;
  height: 100px;
  border-bottom: 3px dashed #466be3;
  /* padding: 5px; */
  /* Aqui no puede haber padding porque al momento de guardar y comsumir nuevamente la api, se van a mover los elementos */
}

/* .position {
  border: 1px red solid;
  padding: 3px;
} */

h3 {
  clear: left;
}

* {
  box-sizing: border-box;
  margin-top: 0px;
}

body,
html {
  font-family: "RobotoRegular";
  width: 100%;
  height: 100%;
}

body {
  padding: 0px;
  background-color: #333;
  color: #fff;
  margin: 0px;
  padding: 0px;
}

.magnify {
  z-index: 20;
  border: 3px solid #999;
  position: relative;
  width: 900px;
  height: 640px;
  margin: 0px auto;
  overflow: hidden;
}

.magnify img {
  display: block;
}

.element_to_magnify {
  position: relative;
  width: 900px;
  height: 640px;
}

.element_to_magnify a {
  display: block;
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: rgba(200, 200, 200, 0.25);
  padding: 4px;
  font-size: 18px;
  color: #fff;
}

.element_to_magnify img {
  width: 100%;
  height: auto;
}

.magnify_glass {
  z-index: 300;
  position: absolute;
  border: 4px solid #ccc;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.modal-o {
  border: 2px solid #466be3 !important;
  border-radius: 14px !important;
}

.ocupation__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.login___txt_title {
  font-family: "AsapRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}

.login___txt_sms {
  font-family: "ProximaNovaRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.alert__button:hover {
  background-color: #324ca1 !important;
}

@media (min-width: 1201px) {
  .screen-size {
    display: none;
  }
}

@media (max-width: 1023px) {
  .screen-size {
    top: 0;
  }
}
</style>
